export const environment = {
    development: false,
    production: false,
    baseUri: "https://secure.test.icuregswe.org",
    apiBaseUri: "https://secure.test.icuregswe.org/api",
    authConfig: {
        authority: "https://secure.icuregswe.org/authority",
        redirectUrl: "https://secure.test.icuregswe.org/signin",
        postLogoutRedirectUri: "https://secure.test.icuregswe.org/signout",
        clientId: "indataportal",
        scope: "openid profile roles indata_api",
        responseType: "code",
        silentRenew: true,
        useRefreshToken: true,
        renewTimeBeforeTokenExpiresInSeconds: 30,
        disablePkce: false,
        secureRoutes: ["https://secure.test.icuregswe.org/api"],
    },
    appVersion: require("../../package.json").version
};
