
<div class="bg-skyblue-dark py-3 py-xl-4 mb-3">
    <a class="btn btn-sm btn-secondary ms-4 position-absolute" [routerLink]="'/'"><i class="bi bi-chevron-left me-2"></i>Tilllbaka</a>
    <h3 class="text-center mb-0 mt-5 mt-sm-0">Instruktionsfilmer</h3>
</div>

<div class="container">
    <ng-container *ngIf="selectedElearning()">
        <div class="row justify-content-center mb-4">
            <div class="col-xl-6 col-lg-8 col-md-10">
                <h4 class="text-center fst-italic border-bottom">{{ selectedElearning()?.name }}</h4>
                <h6 class="p-2">{{ selectedElearning()?.description }}</h6>
                <div class="border" style="padding:1px">
                    <div class="ratio ratio-16x9">
                        <iframe [src]="selectedElearning()?.link" [title]="selectedElearning()?.name" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>

<ng-container *ngIf="videos.length > 1 || !selectedElearning() || selectedElearning()?.id != videos[0].id">
    <div *ngIf="selectedElearning()" class="bg-skyblue-dark py-3 mb-3">
        <h5 class="text-center fst-italic mb-0">Välj bland alla instruktionsfilmer</h5>
    </div>

    <div class="container">
        <div class="row justify-content-center justify-content-lg-start">
            <ng-container *ngFor="let video of videos">
                <div *ngIf="!selectedElearning() || selectedElearning()?.id != video.id"
                    class="col-xl-4 col-lg-6 col-md-10">
                    <div class="card">
                        <div class="card-header" (click)="selectVideo(video.id)">
                            <h6>{{video.name}}</h6>
                        </div>
                        <div class="card-body p-0">
                            <p class="px-3 py-2 border-bottom">{{video.description}}</p>
                            <div style="padding:1px">
                                <div class="ratio ratio-16x9">
                                    <iframe [src]="video.link" [title]="video.name" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>