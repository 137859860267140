import { Component } from "@angular/core";
import { Router } from "@angular/router";

@Component({
    selector: "app-webform",
    template: `
    <p>Redirecting to secure.icuregswe.org...</p>
    ` 
})
export class WebformComponent {

    constructor(private router: Router) {
        this.router.navigate([""]);
    }

}
