import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "@environments/environment";
import { ValueLabelOption } from "@shared/models/value-label-option";
import { MtalCommissionData, MtalEduData, MtalGeneralData, MtalPMData } from "@shared/models/mtal-data";

@Injectable({
    providedIn: "root"
})
export class MtalService {
    constructor( private http: HttpClient ) { }

    /*** 
     * API FUNCTIONS
     ***/

    public getGeneral(): Observable<MtalGeneralData> {
        const url = environment.apiBaseUri + "/mtal/getGeneral";
        return this.http.get<MtalGeneralData>(url);
    }

    public getCommissions(): Observable<MtalCommissionData[]> {
        const url = environment.apiBaseUri + "/mtal/getCommissions";
        return this.http.get<MtalCommissionData[]>(url);
    }

    public getAllPM(): Observable<MtalPMData[]> {
        const url = environment.apiBaseUri + "/mtal/getPM";
        return this.http.get<MtalPMData[]>(url);
    }

    public getPMTypeNames(): Observable<ValueLabelOption[]> {
        const url = environment.apiBaseUri + "/mtal/getPMTypeNames";
        return this.http.get<ValueLabelOption[]>(url);
    }
    
    public getEdus(): Observable<MtalEduData[]> {
        const url = environment.apiBaseUri + "/mtal/getEdus";
        return this.http.get<MtalEduData[]>(url);
    }

    public getNationalEduNames(): Observable<ValueLabelOption[]> {
        const url = environment.apiBaseUri + "/mtal/getNationalEduNames";
        return this.http.get<ValueLabelOption[]>(url);
    }


    /***
     * DATA MAPPING
     ***/

    public getPMTypeWithEndDates(pmArr: MtalPMData[], pmType?: number): MtalPMData[] {
        if (pmType != null) {
            return this.sortTypedPMs(pmType, pmArr);
        }
        else {
            const outputArr: MtalPMData[] = [];
            for (let i = 1; i < pmArr.length+1; i++) {
                const sortedTypedPMs = this.sortTypedPMs(i, pmArr);
                sortedTypedPMs.forEach(pm => outputArr.push(pm));
            }
            return outputArr;
        }
    }

    /* Förutsätter att det finns minst ett pm av varje typ, vilket servern skapar vid behov.
       Behöver beräknade "endDate" för att fungera (se "getPMTypeWithEndDates") */
    public getTypedList(pmArr: MtalPMData[]): MtalPMData[] {
        const outputArr: MtalPMData[] = [];
        const today = new Date();
        for (let i = 1; i < pmArr.length+1; i++) {
            pmArr.forEach(pm => {
                if (pm.infMtalOrganPMTypeId == i && pm.fromDate < today && (pm.endDate == null || pm.endDate > today)) {
                    outputArr.push(pm);
                }
            })
        }
        return outputArr;
    }


    /***
     * HELPER FUNCTIONS
     ***/

    public getCategoryText(category: number): string {
        switch(category) {
            case 1: return "I";
            case 2: return "II";
            case 3: return "III";
            default: return "Ej angivet";
        }
    }

    private sortTypedPMs(pmType: number, pmArr: MtalPMData[]): MtalPMData[] {
        const typedPMs = pmArr.filter(pm => pm.infMtalOrganPMTypeId == pmType);
        let sortedTypedPMs = typedPMs.sort(
            (a: MtalPMData, b: MtalPMData) => {
                if (a.fromDate < b.fromDate) return 1;
                if (a.fromDate > b.fromDate) return -1;
                return 0;
            }); 
        sortedTypedPMs = this.setEndDates(sortedTypedPMs);
        return sortedTypedPMs;
    }
    private setEndDates(PMarr: MtalPMData[]): MtalPMData[] {
        let prevStartDate: Date|null = null;
        PMarr.forEach(pm => {
            pm.endDate = prevStartDate;
            prevStartDate = pm.fromDate;
        })
        return PMarr;
    }
}