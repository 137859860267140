<header id="header">
    <div class="container">
        <div class="row">
            <div id="logo-column" class="col-lg-5 pl-md-0">
                <div id="logo" class="text-start text-md-start py-2">
                    <a href="https://www.icuregswe.org/" target="_blank">
                        <img src="assets/images/SIR_logo_oneline_000.svg"
                             onerror="this.onerror = null; this.src='assets/images/SIR_logo_oneline_000.png'"
                             alt="Svenska Intensivvårdsregistret (logotyp)" />
                    </a>
                </div>
            </div>
            <div class="col-lg-7">
                <div class="text-end my-2 my-lg-4">
                    <h4 class="mb-2" [title]="appVersion">Indataportalen</h4>
                    <div class="mb-3">
                        <ul *ngIf="isAuthenticated" class="usability-menu d-inline-block float-none">
                            <li ngbDropdown>
                                <a ngbDropdownToggle>
                                    <i class="bi bi-person-circle me-2" style="font-size:1rem"></i>{{userName}}
                                </a>
                                <div class="dropdown-menu" ngbDropdownMenu>
                                    <a ngbDropdownItem (click)="logout()">Logga ut</a>
                                    <a ngbDropdownItem *ngIf="userUnits.length > 1" (click)="changeEnhet()">Byt avdelning</a>
                                    <a ngbDropdownItem href="https://inloggning.net/gdpr" target="_blank">GDPR</a>
                                </div>
                            </li>
                            <li>
                                <a href="https://www.icuregswe.org/">
                                    Tillbaka till webbplatsen&ensp;<i class="chevron chevron-right"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <p *ngIf="userUnit" class="current-dep badge badge-info small m-0">Din avdelning: {{userUnit}}</p>
                </div>
            </div>
        </div>
    </div>
</header>

<div *ngIf="(isAuthenticated && isAdmin()); else notAuthOrNotAdmin" id="main-menu" class="mb-3">
    <div class="container nav justify-content-center">
        <div class="header-menu-item"><a class="nav-link" [routerLink]="'/'"><i class="bi bi-house-door me-3"></i>Startsida</a></div>
        <div class="header-menu-item"><a class="nav-link" [routerLink]="'/admin/menu'" routerLinkActive="active">SIR Admin</a></div>
    </div>
</div>
<ng-template #notAuthOrNotAdmin>
    <div id="fake-menu" class="mb-3"></div>
</ng-template>
