import { Component, Inject, OnInit } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { LoginResponse, OidcSecurityService } from "angular-auth-oidc-client";
import { environment } from "@environments/environment";
import { RollService } from "@core/services/roll.service";
import { UserData } from "@shared/models/user-data";

@Component({
    selector: "app-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit {
    idToken: any;
    isAuthenticated = false;
    userName = "";
    userUnit = "";
    isProduction = false;
    appVersion = "";
    userRoles: string[] = [];
    userUnits: any[] = [];

    constructor(
        private oidcSecurityService: OidcSecurityService,
        private rollService: RollService,
        @Inject(DOCUMENT) private document: Document
        ) {
        if (environment["production"]) 
            this.isProduction = environment["production"];

        this.appVersion = environment.appVersion;
    }

    ngOnInit() {
        this.oidcSecurityService.checkAuth().subscribe((loginResponse: LoginResponse) => {
            const { isAuthenticated, userData, accessToken, idToken, configId } = loginResponse;
            this.idToken = idToken;
            this.isAuthenticated = isAuthenticated;
            if (isAuthenticated) {
                this.getUserData();
            }
        });
    }

    private getUserData() {
        this.oidcSecurityService.getUserData().subscribe(user => {
            this.userName = user.given_name + " " + user.family_name;
            this.userUnit = user.unit_name;
            if (!user.unit_name) this.changeEnhet();
        });
        this.rollService.getUserRoles().subscribe({
            next: data => this.userRoles = data,
            error: error => console.error(error)
        });
        this.rollService.getUserUnits().subscribe({
            next: data => this.userUnits = data,
            error: error => console.error(error)
        });
    }

    isAdmin(): boolean {
        return this.userRoles.indexOf("admin") > -1;
    }

    logout() {
        sessionStorage.setItem("signoutRedirect", environment.authConfig.authority + "/account/logout");
        this.oidcSecurityService.logoffAndRevokeTokens().subscribe((result) => console.log(result));
    }

    changeEnhet() {
        sessionStorage.setItem("signoutRedirect", environment.baseUri);
        this.oidcSecurityService.logoffAndRevokeTokens().subscribe((result) => console.log(result));
    }
}
