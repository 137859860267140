import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "pagination",
    templateUrl: "./pagination.component.html",
    styleUrls: ["./pagination.component.scss"]
})
export class PaginationComponent {

    @Input() numberOfRows!: number;
    @Input() currentPage!: number;
    @Input() rowsPerPage!: number;
    @Input() pagesVisible = 5;
    @Input() showFirstLastLink = true;
    @Input() showRowsPerPageAlt = true;
    @Input() rowsPerPageAlts: number[] = [10, 25, 50, 100];
    @Output() currentPageChange = new EventEmitter<number>();
    @Output() rowsPerPageChange = new EventEmitter<number>();


    public changedRowsPerPage(rows: number) {
        this.rowsPerPage = rows;
        this.rowsPerPageChange.emit(rows);
    }
}
