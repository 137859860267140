
<div *ngIf="(message && message.length > 0) || 
            (messageArr && messageArr.length > 0)" 
     class="alert alert-danger">

    <div class="row g-3">
        <div class="col-auto align-self-center">
            <i class="bi bi-exclamation-triangle-fill" style="font-size:1.5rem"></i>
        </div>
        <div class="col align-self-center">
            <ng-container *ngIf="messageArr && messageArr.length > 0">
                <p *ngFor="let error of messageArr" class="mb-1">{{error}}</p>
            </ng-container>
            <ng-container *ngIf="message && message.length > 0">
                <p class="mb-0">{{message}}</p>
            </ng-container>
        </div>
    </div>

</div>
