import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: "dateTimeAgo",
    pure: true
})
export class DateTimeAgoPipe implements PipeTransform {

    /**
     * @param value The date expression: a `Date` object,  a number
     * (milliseconds since UTC epoch), or an ISO string (https://www.w3.org/TR/NOTE-datetime).
     * @param maxIntervals The number of intervals-texts you want to show,
     * (if null then all intervals is shown)
     * @param useShortTexts Whether or not to show shortened texts
     * (The last text in sentence is not shortened)
     * @returns A date string in the desired format.
     */
    transform(value: any, maxIntervals?: number, useShortTexts?: boolean): any {
        if (value) {
            let seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
            if (seconds < 30) // less than 30 seconds ago == right now
                return "Just nu";
            const intervals = {
                "år": 31536000,
                "månad": 2592000,
                "vecka": 604800,
                "dag": 86400,
                "timme": 3600,
                "minut": 60,
                "sekund": 1
            };
            let counter;
            let dateAgoText = "";
            let intervalIndex = 0;
            maxIntervals = maxIntervals ? maxIntervals : 7;
            for (const i in intervals) {
                counter = Math.floor(seconds / intervals[i]);
                if (counter > 0 && intervalIndex < maxIntervals) {
                    seconds = seconds - (counter * intervals[i]);
                    if (counter === 1) {
                        // singular (ex. 1 day ago)
                        if (!useShortTexts || intervalIndex == maxIntervals-1)
                            dateAgoText += " " + counter + " " + i;
                        else {
                            const shortText = this.getShortText(i);
                            dateAgoText += counter + " " + shortText;
                        }
                    } else {
                        // plural (ex. 2 days ago)
                        let text = counter + " ";
                        if (!useShortTexts || intervalIndex == maxIntervals-1) {
                            const pluralText = this.getPluralText(i);
                            text += pluralText;
                        } else {
                            const shortText = this.getShortText(i);
                            text += shortText;
                        }
                        dateAgoText += " " + text;
                    }
                    intervalIndex++;
                }
            }
            return dateAgoText + " sedan";
        }
        return value;
    }

    private getShortText(i: string): string {
        switch(i) {
            case "år": return "år";
            case "månad": return "mån";
            case "vecka": return "v";
            case "dag": return "dag";
            case "timme": return "h";
            case "minut": return "min";
            case "sekund": return "s";
            default: return i;
        }
    }

    private getPluralText(i: string): string {
        switch(i) {
            case "år": return "år";
            case "månad": return "månader";
            case "vecka": return "veckor";
            case "dag": return "dagar";
            case "timme": return "timmar";
            case "minut": return "minuter";
            case "sekund": return "sekunder";
            default: return i;
        }
    }

}