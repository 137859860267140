import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpHeaders } from "@angular/common/http";

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const httpRequest = req.clone({
      headers: req.headers
      .set("Cache-Control", "no-cache")
      .set("Pragma", "no-cache") 
      .set("Expires", "Sat, 01 Jan 1970 00:00:00 GMT")
    });

    return next.handle(httpRequest);
  }
}
