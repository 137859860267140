<div class="modal-header">
    <h4 class="modal-title" id="confirm-modal" [innerHTML]="modalTitle"></h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.close('Cross click')"></button>
</div>
<div class="modal-body" [innerHTML]="modalBody"></div>
<div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-outline-secondary" 
            (click)="modal.close('Cancel click')">Avbryt</button>
    <button type="button" class="btn" [class.btn-danger]="isConfirmDelete" [class.btn-success]="!isConfirmDelete" 
            (click)="modal.close('Confirmed');">{{confirmBtnText}}</button>
</div>