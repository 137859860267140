import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "@environments/environment";
import { Q1Data } from "@shared/models/q1-data";

@Injectable({
    providedIn: "root"
})
export class Q1Service {
    constructor( private http: HttpClient ) { }

    public getLatest(): Observable<Q1Data> { 
        const url = environment.apiBaseUri + "/q1/getLatest";
        return this.http.get<Q1Data>(url);
    }

}
