import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: "dateAgo",
    pure: true
})
export class DateAgoPipe implements PipeTransform {

    /**
     * @param value The date expression: a `Date` object,  a number
     * (milliseconds since UTC epoch), or an ISO string (https://www.w3.org/TR/NOTE-datetime).
     */
    transform(value: any): any {
        if (value) {
            const days = Math.floor((+new Date() - +new Date(value)) / 86400000);
            if (days < 1)
                return "Idag";
            if (days < 2)
                return "1 dag sedan";
            if (days > 365) {
                const years = Math.floor(days / 365);
                return `${years} år sedan`;
            }
            if (days > 30) {
                const months = Math.floor(days / 30);
                if (months == 1) 
                    return "1 månad sedan";
                else
                    return `${months} månader sedan`;
            }
            return `${days} dagar sedan`;
        }
        return value;
    }
}
