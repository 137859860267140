import { Component, Input } from "@angular/core";

@Component({
    selector: "service-error",
    templateUrl: "service-error.component.html"
})
export class ServiceErrorComponent {
    
    @Input()
    message: string|null = null;

    @Input()
    messageArr: string[]|null = null;

}