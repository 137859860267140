<footer id="footer" class="page-footer">
    <div class="container">
        <div id="contact-info" class="px-2 px-sm-3">
            <div class="row">
                <div class="col-6 col-md-3 col-lg-3">
                    <h3>Kontakt</h3>
                    <a href="http://www.icuregswe.org"><i>www.icuregswe.org</i></a>
                </div>
                <div class="col-6 col-md-3 col-lg-3">
                    <p>
                        <b>Postadress</b>
                    </p>
                    <p>
                        Svenska Intensivvårdsregistret<br />
                        c/o Snårstadtorp 511<br />
                        655 93 Karlstad
                    </p>
                </div>
                <div class="col-6 col-md-3 col-lg-2">
                    <p>
                        <b>E-mail</b>
                    </p>
                    <p>
                        <a href="mailto:sir@icuregswe.org">sir@icuregswe.org </a>
                    </p>
                </div>
                <div class="col-6 col-md-3 col-lg-2">
                    <p>
                        <b>Telefon</b>
                    </p>
                    <p>
                        <a href="tel:+46108391490">(+46) 010-8391490</a>
                    </p>
                </div>
            </div>
        </div>
        <div id="links" class="socialmedia-row px-2 px-sm-3">
            <div id="powered-by" class="text-lg-end">
                <a href="https://www.otimo.se/" target="_blank">
                    Powered by<i class="ms-2"></i>
                    <img src="assets/images/OTIMO_logo_vit.svg"
                            onerror="this.onerror = null;this.src = 'assets/images/OTIMO_logo_vit-500px.png'"
                            alt="Otimo Data AB (logotyp)"/>
                </a>
            </div>
        </div>
    </div>
</footer>
