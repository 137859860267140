import { DOCUMENT, Location } from "@angular/common";
import { Component, ElementRef, Inject, OnInit, signal, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
    selector: "app-gdpr",
    template: `
        <h3 class="display-3 text-center">GDPR / SIR</h3>
    ` 
})
export class GDPRComponent implements OnInit {

    constructor (
        private route: ActivatedRoute,
        @Inject(DOCUMENT) private document: Document
    ) { }

    ngOnInit(): void {
        const token = this.route.snapshot.params["token"];
        this.sendToken(token);
    }

    private sendToken(token: string|null) {
        const baseUrl = "https://inloggning.net/gdpr";

        if (!token || token.length == 0) {
            this.document.location.href = baseUrl;
        }
        else {
            const url = baseUrl + "/sir?token="+token;
            this.document.location.href = url;
        }
    }

}
