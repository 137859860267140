<div class="row">
    <div class="col-lg-3">
        <p class="mt-3 mb-0 fst-italic">
            <ng-container *ngIf="numberOfRows > rowsPerPage">
                Visar rad {{(currentPage*rowsPerPage)-(rowsPerPage-1)}} till {{(currentPage*rowsPerPage) < numberOfRows ? currentPage*rowsPerPage : numberOfRows}} (av {{numberOfRows}})
            </ng-container>
            <ng-container *ngIf="numberOfRows <= rowsPerPage">
                Visar totalt {{numberOfRows}} rader
            </ng-container>
        </p>
    </div>
    <div class="col-lg-6">
        <ngb-pagination *ngIf="numberOfRows > rowsPerPage"
            [collectionSize]="numberOfRows"
            [page]="currentPage"
            (pageChange)="currentPageChange.emit($event)"
            [pageSize]="rowsPerPage"
            [maxSize]="pagesVisible"
            [rotate]="true"
            [ellipses]="false"
            [boundaryLinks]="showFirstLastLink && (pagesVisible < (numberOfRows/rowsPerPage))">
        </ngb-pagination>
    </div>
    <div class="col-lg-3 text-lg-end text-center">
        <div *ngIf="showRowsPerPageAlt && numberOfRows > rowsPerPageAlts[0]" class="rows-per-page">
            <div class="input-group input-group-sm">
                <span class="input-group-text">
                    Rader per sida:
                </span>
                <select class="form-control" [ngModel]="rowsPerPage" (ngModelChange)="changedRowsPerPage($event)">
                    <option *ngFor="let alt of rowsPerPageAlts">{{alt}}</option>
                </select>
            </div>
        </div>
    </div>
</div>
