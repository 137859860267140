import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: "personnr",
    pure: true
})
export class PersonnrPipe implements PipeTransform {

    /**
     * @param value Personnr
     */
    transform(value: string): string {
        if (value.length == 12) {
            const date = value.substring(0,8);
            const xxxx = value.substring(8,12);
            return date + "-" + xxxx;
        }
        if (value.length == 10) {
            const date = value.substring(0,6);
            const xxxx = value.substring(6,10);
            return date + "-" + xxxx;
        }
        return value;
    }
}
