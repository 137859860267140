import { AuthInterceptor, AuthModule } from "angular-auth-oidc-client";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { environment } from "@environments/environment";

@NgModule({
    imports: [AuthModule.forRoot({
        config: environment.authConfig
    })],
    exports: [AuthModule],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    ]

})

export class AuthConfigModule { }
