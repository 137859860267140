import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable, of, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";


@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    if (error.status == 503) {
                        this.router.navigate(["appMainMenu"]);
                    }
                    if (error.status == 500) {
                        return throwError(() => new Error("Internt systemfel"));
                    }
                    if (error.status == 401 || error.status == 402) {
                        return throwError(() => new Error("Din inloggning har gått ut, vänligen ladda om sidan."));
                    }
                    if (error.status == 403) {
                        return throwError(() => new Error(`Du har ej behörighet. (${error.url})`));
                    }
                    let errorMsg = error.error;
                    if (error.message) errorMsg = error.message;
                    if (error.error?.["message"]) errorMsg = error.error["message"];
                    if (error.error?.["errors"]) errorMsg = JSON.stringify(error.error["errors"]);
                    return throwError(() => errorMsg);
                })
            )
    }
}
