import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

export class NotificationData {
    id!: number;
    messageOwner?: MessageOwner;
    importanceLevel: ImportanceLevel|null = null;
    text?: string;
    showFrom?: Date;
    showFromDateStr?: NgbDateStruct;
    showUntil?: Date;
    showUntilDateStr?: NgbDateStruct;
    created?: Date;
}

export enum MessageOwner {
    Otimo = 1,
    SIR = 2
}

export enum ImportanceLevel {
    Low = 1,
    Middle = 2,
    High = 3
}
