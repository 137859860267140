import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "@environments/environment";
import { SaveResponse } from "@shared/models/save-response";
import { PersonNummerTypWebb, VtfLookUpModel, VtfLookUpModelRequest } from "@shared/models/avlidna/vtf-lookup";
import { AdjustKomplettToVtfModel } from "@shared/models/avlidna/adjust-komplett-to-vtf";
import { HideProtocolModel } from "@shared/models/avlidna/hide-protocol";
import { AvlidnaUnmatchedData } from "@shared/models/avlidna/unmatched-data";

@Injectable({
    providedIn: "root"
})
export class AvlidnaService {
    constructor( private http: HttpClient ) { }

    public getUnmatchedCount(protocolYear: number): Observable<number> {
        const ctrlUri = this.getCtrlUrl(protocolYear);
        const url = environment.apiBaseUri + ctrlUri + "/getUnmatchedCount";
        return this.http.get<number>(url);
    }

    public getAllUnmatched(protocolYear: number, hidden = false): Observable<AvlidnaUnmatchedData[]> {
        const ctrlUri = this.getCtrlUrl(protocolYear);
        let url = environment.apiBaseUri + ctrlUri + "/getAllUnmatched";
        if (hidden) url += "?hidden=true"
        return this.http.get<AvlidnaUnmatchedData[]>(url);
    }

    public getAllVtfMissingProtocol(protocolYear: number, startdate?: string, stopdate?: string): Observable<AvlidnaUnmatchedData[]> {
        const ctrlUri = this.getCtrlUrl(protocolYear);
        let url = environment.apiBaseUri + ctrlUri + "/getAllVtfMissingProtocol";
        if (startdate && startdate.length > 0) { 
            url += "?startdate="+startdate;
            if (stopdate && stopdate.length > 0) url += "&stopdate="+stopdate;
        }
        return this.http.get<AvlidnaUnmatchedData[]>(url);
    }

    public adjustKomplettToVtf(protocolYear: number, avdId: number, avlidenId: number, vtfId: number): Observable<SaveResponse> {
        const ctrlUri = this.getCtrlUrl(protocolYear);
        const url = environment.apiBaseUri + ctrlUri + "/adjustKomplettToVtf";
        const request = new AdjustKomplettToVtfModel(avdId, avlidenId, vtfId);
        return this.http.post<SaveResponse>(url, request);
    }

    public hideUnhideProtocol(protocolYear: number, hide: boolean, protocolId: number): Observable<any> {
        const ctrlUri = this.getCtrlUrl(protocolYear);
        const url = environment.apiBaseUri + ctrlUri + "/hideUnhideProtocol";
        const request = new HideProtocolModel(hide, protocolId);
        return this.http.post<any>(url, request);
    }

    public vtfLookUp(protocolYear: number, avdId: number, personNrTypWebb: PersonNummerTypWebb, personNr: string, avlidenTid: string): Observable<VtfLookUpModel> {
        const ctrlUri = this.getCtrlUrl(protocolYear);
        const url = environment.apiBaseUri + ctrlUri + "/vtfLookUp";
        const avlidenDate = new Date(avlidenTid).toSwedishUTCDate();
        const request = new VtfLookUpModelRequest(avdId, personNrTypWebb, personNr, avlidenDate!);
        return this.http.post<VtfLookUpModel>(url, request);
    }

    private getCtrlUrl(protocolYear: number): string {
        switch(protocolYear) {
            case 2020: return "/avlidna2020";
            case 2024: return "/avlidna2024";
            default: return "/avlidna2024";
        }
    }

}