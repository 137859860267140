import { Component, signal } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ActivatedRoute, ParamMap } from "@angular/router";

@Component({
    templateUrl: "elearning.component.html",
    styleUrls: ["elearning.component.scss"]
})
export class ElearningComponent {

    constructor(
        private activeRoute: ActivatedRoute,
        private domSanitizer: DomSanitizer
    ) { 
        this.activeRoute.paramMap.subscribe((params: ParamMap) => {
            const videoId = params.get("videoId");
            if (videoId) {
                this.selectVideo(videoId);
            }
        })
    }

    public selectedElearning = signal<ElearningItem|undefined>(undefined);

    public selectVideo(videoId: string) {
        const video = this.videos.find(e => e.id == videoId);
        this.selectedElearning.set(video);
    }

    private getIframeSrc(videoId): SafeResourceUrl {
        return this.domSanitizer.bypassSecurityTrustResourceUrl( "https://player.vimeo.com/video/" + videoId );
    }

    public videos: ElearningItem[] = [
        {
            id: "345934920", link: this.getIframeSrc("345934920"),
            name: "Webbvalideringsprogrammet", description: "Beskriver hur man använder indataportalens webbvalideringsprogram för inläsning av filer."
        }
    ];

}

class ElearningItem {
    id!: string;
    link!: SafeResourceUrl;
    name!: string;
    description!: string;
}