import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "@environments/environment";
import { NotificationData } from "@shared/models/notification-data";

@Injectable({
    providedIn: "root"
})
export class NotificationService {
    constructor( private http: HttpClient ) { }

    public getAll(): Observable<NotificationData[]> {
        const url = environment.apiBaseUri + "/notification/getAll";
        return this.http.get<NotificationData[]>(url);
    }

    public get(id: number): Observable<NotificationData> {
        const url = environment.apiBaseUri + "/notification/get/" + id;
        return this.http.get<NotificationData>(url);
    }

    public save(data: NotificationData): Observable<any> {
        data.showFrom = data.showFrom?.toSwedishUTCDate();
        data.showUntil = data.showUntil?.toSwedishUTCDate();
        const url = environment.apiBaseUri + "/notification/save";
        return this.http.post<any>(url, data);
    }

    public delete(id: number): Observable<any> {
        const url = environment.apiBaseUri + "/notification/delete/" + id;
        return this.http.delete<any>(url);
    }

}
