import { Component, Input } from "@angular/core";

@Component({
    selector: "info-circle",
    template: `
        <div class="info-circle"
             [class.info-circle-sm]="size==='sm'"
             [class.info-circle-lg]="size==='lg'">
             <i class="bi bi-info-lg"></i>
        </div>
    `,
    styleUrls: [ "info-circle.component.scss" ]
})
export class InfoCircleComponent {
    @Input() size = "md";
}