import { Component, Injectable, Input } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable } from "rxjs";

@Component({
    selector: "app-confirm-modal",
    standalone: true,
    templateUrl: "./confirm-modal.component.html"
})
export class ConfirmModalComponent {

    constructor(public modal: NgbActiveModal) {}

    @Input()
    modalTitle!: string;
    @Input()
    modalBody!: string;
    @Input()
    confirmBtnText!: string;
    @Input()
    isConfirmDelete!: boolean;

}

@Injectable()
export class ConfirmModalService {
    constructor(private modal: NgbModal) {}

    show(title: string, body: string, confirmBtnText: string, isConfirmDelete: boolean, size = "lg"): Observable<string> {
        const modalRef = this.modal.open(ConfirmModalComponent, { backdrop: "static", size: size });
        modalRef.componentInstance.modalTitle = title;
        modalRef.componentInstance.modalBody = body;
        modalRef.componentInstance.confirmBtnText = confirmBtnText;
        modalRef.componentInstance.isConfirmDelete = isConfirmDelete;

        return modalRef.closed;
    }
}