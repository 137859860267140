import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule } from "@angular/forms";

import { ConfirmModalService } from "./components/confirm-modal/confirm-modal.component";
import { PaginationComponent } from "./components/pagination/pagination.component";
import { ServiceErrorComponent } from "./components/service-error/service-error.component";
import { InfoCircleComponent } from "./components/info-circle/info-circle.component";

import { DateAgoPipe } from "./pipes/date-ago.pipe";
import { DateTimeAgoPipe } from "./pipes/datetime-ago.pipe";
import { PersonnrPipe } from "./pipes/personnr-pipe";

@NgModule({
    declarations: [
        InfoCircleComponent,
        PaginationComponent,
        ServiceErrorComponent,
        DateAgoPipe,
        DateTimeAgoPipe,
        PersonnrPipe
    ],
    imports: [
        CommonModule,
        FormsModule,
        NgbModule
    ],
    exports: [
        InfoCircleComponent,
        PaginationComponent,
        ServiceErrorComponent,
        DateAgoPipe,
        DateTimeAgoPipe,
        PersonnrPipe
    ],
    providers: [
        ConfirmModalService
    ]
})
export class SharedComponentsModule { }
