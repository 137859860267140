export class VtfLookUpModelRequest {
    constructor(
        public avdId: number,
        public personNrTyp: PersonNummerTypWebb,
        public personNr: string,
        public avlidenTid: Date
    ) {}
}

export class VtfLookUpModel {
    validMatch?: VtfLookupData;
    invalidMatchesProtocol: VtfLookupData[] = [];
    invalidMatchesVtf: VtfLookupData[] = [];
}

export class VtfLookupData {
    ivaId?: number;
    ivaNamnKort?: string;
    vtfId?: number;
    idBegrepp?: string;
    personId?: number;
    inskrivningstid?: Date;
    utskrivningstid?: Date;
    avlidentid?: Date;

    personNummer?: string;
    personNummerTyp?: PersonNummerTyp;
    personNummerTypWebb?: PersonNummerTypWebb;
    personNummerTypWebbText?: string;

    vårdtyp?: SIRVardTyp;
    vardTypText?: string;
    vårdresultat?: SIRVardresultat;
    vardresultatText?: string;

    protokollMatchningsFel?: ProtokollMatchningsFel[];
    protocolMatchingErrorString?: string;
    vtfMatchningsFel?: VtfMatchningsFel[];
    vtfMatchingErrorString?: string;
}

export enum PersonNummerTyp {
    EjBesvarad = 0,
    FelaktigtSvar = 1,
    Korrekt = 2,
    Reserv = 3,
    Hemlig = 4,
    Okand = 5
}

export enum PersonNummerTypWebb {
    Korrekt = 1,
    Reserv = 2,
    Okand = 3
}

export enum SIRVardTyp {
    Fel = 1,
    HIA = 2,
    PostOp = 3,
    IVA = 4,
    Ovrig = 5,
    TIVA = 6,
    BIVA = 7
}

export enum SIRVardresultat {
    Avliden = 6,
    Levande = 7,
    EjUtskriven = 8
}

export enum ProtokollMatchningsFel {
    FelPersonnummer = 1,
    FelPersonnrTyp = 2,
    FelAvlidentid = 4,
    RegAnnanIVA = 8
}

export enum VtfMatchningsFel {
    FelVardresultat = 1,
    FelVardtyp = 2
}