import { NgModule, LOCALE_ID } from "@angular/core";
import { registerLocaleData, APP_BASE_HREF, PlatformLocation, CommonModule } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AuthConfigModule } from "./auth/auth-config.module";

import "./global";

import localeSv from "@angular/common/locales/sv";
registerLocaleData(localeSv, "sv");

import { HttpDateInterceptor } from "@core/interceptors/http-date.interceptor";
import { HeaderInterceptor } from "@core/interceptors/http-header.interceptor";
import { HttpErrorInterceptor } from "@core/interceptors/http-error.interceptor";

import { SharedComponentsModule } from "@shared/shared-components.module";

import { HeaderComponent } from "./layout/header/header.component";
import { FooterComponent } from "./layout/footer/footer.component";
import { OverviewComponent } from "./pages/overview/overview.component";
import { ElearningComponent } from "./pages/elearning/elearning.component";

export function getBaseHref(platformLocation: PlatformLocation): string {
  return platformLocation.getBaseHrefFromDOM();
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    OverviewComponent,
    ElearningComponent
  ],
  imports: [
    AppRoutingModule,
    AuthConfigModule,
    BrowserModule,
    CommonModule,
    HttpClientModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    SharedComponentsModule
  ],
  providers: [
    {
      provide: APP_BASE_HREF,
      useFactory: getBaseHref,
      deps: [PlatformLocation]
    },
    { provide: LOCALE_ID, useValue: "sv" },
    {
      provide: "externalUrlRedirectResolver",
      useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        window.location.href = (route.data as any).externalUrl;
      }
    },
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpDateInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
