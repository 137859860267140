import { NgbDateStruct, NgbTimeStruct } from "@ng-bootstrap/ng-bootstrap";

export { }

declare global {
    interface Date {
        toNgbDateStruct(): NgbDateStruct;
        toNgbTimeStruct(): NgbTimeStruct;
        toSwedishUTCDate(): Date;
    }
}

Date.prototype.toNgbDateStruct = function () {
    return { year: this.getFullYear(), month: this.getMonth() + 1, day: this.getDate() } as NgbDateStruct;
}

Date.prototype.toNgbTimeStruct = function () {
    return { hour: this.getHours(), minute: this.getMinutes(), second: this.getSeconds() } as NgbTimeStruct;
}

Date.prototype.toSwedishUTCDate = function () {
    if (this) {
        const localeDatum = this?.toLocaleString("sv-SE");
        return new Date(localeDatum + "Z");
    }
    else return this;
}
